import React from 'react';

export default function PostStat({ label, value, ellipsis = false }) {
  return (
    <div className="PostListing-stat">
      <div className="PostListing-statLabel">
        {label}
      </div>
      <div className={ellipsis ? 'PostListing-statValueEllipsis' : 'PostListing-statValue'} title={value}>
        {value}
      </div>
    </div>
  );
}
