import React from 'react';
import MaterialButton from 'app/components/common/MaterialButton';

function Backup({ onClick }) {
  return (
    <MaterialButton
      onClick={onClick}
      styled={false}
    >
      Backup All Posts
    </MaterialButton>
  );
}

export default Backup;
