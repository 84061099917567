import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from 'app/components/AppContainer';

import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import { buildStore } from 'app/redux';
import { loggIn, loggInError } from 'app/redux/app/actions';
import { initializeApi } from 'app/api/api'; // FAKE: api

// TODO: This is only for debug, remove from production build
// import ReactPerfTool from 'react-perf-tool';
// import Perf from 'react-addons-perf';
// import './perftool.css';

import { bindBodyListener } from './iframe';

import saga from 'app/redux/middlewareSagas';

import './index.css';

const sagaMiddleware = createSagaMiddleware();
const store = buildStore(sagaMiddleware);

// Finally render the application
ReactDOM.render(
  <Provider store={store}>
    <div>
      {/*<ReactPerfTool perf={Perf} />*/}
      <AppContainer />
    </div>
  </Provider>,
  document.getElementById('root')
);

initializeApi()
  .then(() => {
    // Start saga middleware
    sagaMiddleware.run(saga);

    // Logg user in
    store.dispatch(loggIn());
  })
  .catch((error) => {
    console.error(error);
    store.dispatch(loggInError())
  });

// Send window size information to parent (wrapper) app
bindBodyListener();