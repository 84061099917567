import { combineReducers } from 'redux';
import * as postsReducers from './posts';

// Blog posts helper functions

// TODO: may be optimized in future
export function checkUniqueUrl (url, postEntities, postId = null) {
  const trimmedUrl = url.trim();
  return Object.keys(postEntities).every(entityId => {
    const post = postEntities[entityId];
    if (postId !== null && postId === post.postId){
      // it's same post, URL may be unchanged
      return true;
    }
    return post.url !== trimmedUrl;
  })
}

export default combineReducers({
  ...postsReducers
});