import React from 'react';
import PostStat from './PostStat';
import MaterialButton from 'app/components/common/MaterialButton';
import { formatDate } from 'app/utils/date';

function Post({ created, updated, title, url, isSelected, isPublished, onPostSelect }) {
  const color = isSelected ? 'rgb(46, 204, 113)' : (isPublished ? null : 'rgb(142, 68, 173)');
  return (
    <MaterialButton
      onClick={onPostSelect}
      styled={false}
      className="PostListing-item materialButton"
      style={color ? { backgroundColor: color } : {}}
    >
      <div className="PostListing-itemTitle" title={title}>
        {title}
      </div>
      <div className="PostListing-itemDetails">
        <PostStat label="Created" value={formatDate(created)} />
        <PostStat label="Updated" value={formatDate(updated)} />
        <PostStat label="URL" value={url} ellipsis />
      </div>
    </MaterialButton>
  );
}

export default Post;
