import React from 'react';
import Input from './Input';
import './PostDetail.css';
import { mdToHtml } from 'app/utils/markdown';
import MaterialButton from 'app/components/common/MaterialButton';
import PostStatesDropdown from './PostStatesDropdown';
import FontAwesome from 'react-fontawesome';
import { formatDate } from 'app/utils/date';
import TextArea from 'app/components/common/TextArea';
// import ReactMarkdown from 'react-markdown';

export default function PostDetail({ ...props }) {
  const {
    id, created, updated, title,
    url, isUniqueUrl, content, description, image,
    postState, onValueChange, onSave
    } = props;
  const { isSaving, isPersisted, isError } = props;
  return (
    <div className={isError ? "PostDetail-errorWrapper" : "PostDetail-wrapper"}>
      {/*<input type="text" value={url} onChange={() => onValueChange('url')} />*/}
      <div className="PostDetail-header">
        <input
          type="text"
          className="PostDetail-title"
          value={title}
          disabled={isSaving}
          onChange={event => onValueChange('title', event.target.value)}
        />
        {id && <div className="PostDetail-id">ID [{id}]</div>}
        <div className="PostDetail-dates">
          <FontAwesome name="calendar-o" /> {formatDate(created)}
          {updated ? <i> (Updated: <FontAwesome name="calendar-o" /> {formatDate(updated)})</i> : ''}
        </div>
        {!isPersisted && <MaterialButton className="PostDetail-headerButton" onClick={onSave} disabled={isSaving}>
          <FontAwesome name="floppy-o" /> SAVE
        </MaterialButton>}
      </div>

      <div className="PostDetail-editItems">
        <div className="PostDetail-editState">
          <span>State</span>
          <PostStatesDropdown
            className="PostDetail-dropdown"
            state={postState}
            onChange={value => onValueChange('postState', value)}
            />
        </div>
        <div className="PostDetail-editUrl">
          <span>Link {!isUniqueUrl && <span className="PostDetail-editUrlNotUnique">[is not unique]</span>}</span>
          <Input value={url} property={'url'} onChange={onValueChange} disabled={isSaving} />
        </div>
        <div className="PostDetail-editImage">
          <span>Image</span>
          <Input value={image} property={'image'} onChange={onValueChange} disabled={isSaving} />
        </div>
        <a href={image} target="_blank" rel="noopener noreferrer">
          <img src={image} alt="blog_image" style={{width: '50px', height: '40px'}} />
        </a>
      </div>

      <div className="PostDetail-editDescription">
        <span>Description</span>
        <Input value={description} property={'description'} onChange={onValueChange} disabled={isSaving} />
      </div>

      <div className="PostDetail-multiedit">
        <TextArea id={id} className="PostDetail-text" onChange={value => onValueChange('content', value)} content={content} disabled={isSaving} />
        {/* TODO: without dangerously -> <ReactMarkdown source={content || ''} />*/}
        <div className="PostDetail-mdOutput" dangerouslySetInnerHTML={{ __html: mdToHtml(content || '') }} />
      </div>
        {!isPersisted && <MaterialButton className="PostDetail-button" onClick={onSave} disabled={isSaving}>
          <FontAwesome name="floppy-o" /> SAVE
        </MaterialButton>}
    </div>
  );
}
