import { connect } from 'react-redux';
import {backupToClipboard} from 'app/api/posts';
import Backup from './Backup';

const mapStateToProps = (state, ownProps) => {
  return {
    // empty
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick () {
    backupToClipboard();
  }
});

const BackupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Backup);

export default BackupContainer;