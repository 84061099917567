import React, { Component } from 'react';
import './MaterialButton.css';

export default class MaterialButton extends Component {

  constructor() {
    super();
    this.timeoutStore = null;
    this.state = {
      pushed: false,
      animationX: 0,
      animationY: 0
    };
  }

  handleOn(event) {
    const animationX = event.nativeEvent.offsetX;
    const animationY = event.clientY;
    this.setState({ pushed: true, animationX, animationY });
  }

  handleOut(event) {
    this.timeoutStore = setTimeout(() => {
      this.setState({ pushed: false });
    }, 700);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutStore);
  }

  render() {
    const isStyled = this.props.styled !== false;
    const isPushed = this.state.pushed === true;
    const { animationX, animationY } = this.state;
    return (
      <div className={this.props.className + ' MaterialButton ' + (isPushed && ' MaterialButton-pushed') + (isStyled && ' MaterialButton-styled')}
        style={this.props.style || {}}
        onClick={this.props.onClick}
        onMouseDown={event => this.handleOn(event)}
        onMouseUp={event => this.handleOut(event)}
      >
        <div style={{ left: animationX + 1, top: animationY - 1 }} className={'MaterialButton-effect ' + (isPushed && 'MaterialButton-effectActive')} />
        {this.props.children}
      </div>
    );
  }
}
