import { connect } from 'react-redux';
import { slideToNextPage, slideToPrevPage, editBlogPost } from 'app/redux/posts/actions';
import PostListing from './PostListing';

const mapStateToProps = (state, ownProps) => {
  const posts = state.posts.listing;
  const { page, pageSize } = state.posts.slider;
  const fromPage = page - 1;
  const toPage = page - 1 + pageSize;
  const pagePosts = posts.slice(fromPage, toPage);
  return {
    posts: pagePosts,
    showPrev: page > 1,
    showNext: posts.length > toPage
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSlideNext() {
    dispatch(slideToNextPage());
  },
  onCreateNew() {
    dispatch(editBlogPost(null));
  },
  onSlidePrev() {
    dispatch(slideToPrevPage());
  }
});

const PostListingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostListing);

export default PostListingContainer;