
// App actions
export const LOGGIN = 'LOGGIN';
export const LOGGIN_ERROR = 'LOGGIN_ERROR';

// Posts actions
export const SELECT_BLOG_POST = 'SELECT_BLOG_POST';
export const CHANGE_BLOG_VALUE = 'CHANGE_BLOG_VALUE';
export const FETCHED_BLOG_POSTS = 'FETCHED_BLOG_POSTS';
export const SAVE_EDITED_BLOG_POST = 'SAVE_EDITED_BLOG_POST';
export const SLIDE_TO_PREV_POSTS_PAGE = 'SLIDE_TO_PREV_POSTS_PAGE';
export const SLIDE_TO_NEXT_POSTS_PAGE = 'SLIDE_TO_NEXT_POSTS_PAGE';
export const BLOG_POST_CREATED = 'BLOG_POST_CREATED';
export const BLOG_POST_UPDATED = 'BLOG_POST_UPDATED';

// Fetching actions
export const FETCH = 'FETCH';
export const FETCH_DONE = 'FETCH_DONE';
export const FETCH_FAIL = 'FETCH_FAIL';
