import React, { useState, useRef, useEffect } from 'react';

// TODO: probably not possible to update back from store when it's active
// TODO: need to wait till there is no change in state and then update - but may be not stable...
// Priority is always on internal state,it needs to be always persisted when it's different from loaded
export default function TextArea({
  id,
  content,
  className,
  isSaving,
  onChange
}) {
  const savingTimeoutRef = useRef(null);

  const [stateContent, setStateContent] = useState(false);

  useEffect(() => {
    setStateContent(null);
    // if (this.props.id !== nextProps.id) {
    //   this.setState({
    //     content: null
    //   });
    // }
  }, [id]);

  const changedValue = event => {
    const value = event.target.value;
    setStateContent(value);
    // Clean up all existing timeouts
    if (savingTimeoutRef.current !== null) {
      clearTimeout(savingTimeoutRef.current);
    }
    // Debounce for 400 ms
    savingTimeoutRef.current = setTimeout(() => {
      onChange(value);
    }, 400);
  };

  return (
    <textarea
      className={className}
      onChange={event => changedValue(event)}
      value={stateContent || content || ''}
      disabled={isSaving}
    />
  );
}
