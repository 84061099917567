import * as actions from 'app/redux/actionTypes';

export function state (state = {loading: true, error: false}, action) {
  switch (action.type) {
    case actions.LOGGIN:
      return {loading: false, error: false};
    case actions.LOGGIN_ERROR:
      return {loading: false, error: true};
    default:
      return state;
  }
} 