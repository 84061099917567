import { database } from 'app/api/api';
import { guid } from 'app/utils/string';
import { normalizePosts } from './schema';

export const backupToClipboard = () => {
  return database()
    .ref('/posts')
    .once('value')
    .then(snapshot => snapshot.val() || {})
    .then(entities => entities)
    .then(text => {
      console.log('[api:posts] Stringyfied entities', text);
      window.prompt("Copy to clipboard: Ctrl+C, Enter", JSON.stringify(text));
    })
}

export const fetchBlogPosts = () => {
  return database()
    .ref('/posts')
    .once('value')
    .then(snapshot => snapshot.val() || {})
    .then(entities => {
      console.log('[api:posts] Fetched blog posts', entities);
      // TODO: not sort here, store listing of IDs to firebase
      const listing = [];
      Object.keys(entities).map(key => listing.push(entities[key]));
      return listing.sort((a, b) => a.created < b.created);
    })
    .then(listing => normalizePosts(listing));
}

/**
 * This will be executed before every save to database,
 * trims and possible other check and normalizations.
 */
export const checkAndCorrectPost = (post) => {
  post.url = post.url ? post.url.trim() : '';
  post.description = post.description ? post.description.trim() : '';
  post.title = post.title ? post.title.trim() : '';
  return post;
}

export const updateBlogPost = (existing, updated) => {
  const updatedObj = checkAndCorrectPost({
    ...existing,
    ...updated
  });
  const db = database();
  return db.ref('/postsArchived')
          .push(existing)
          .then(() => 
            db.ref(`/posts/${existing.postId}`)
            .update(updatedObj)
            .then(() => updatedObj)
    );
}

export const createBlogPost = (data) => {
  const newPost = checkAndCorrectPost({
    postState: 'draft',
    ...data,
    postId: guid(),
    created: new Date().getTime()
  });
  return database()
    .ref(`/posts/${newPost.postId}`)
    .set(newPost)
    .then(() => newPost);
};
