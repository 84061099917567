import React from 'react';

export default function Input({ property, value, disabled, onChange }) {
  return (
    <input
      type="text"
      className="PostDetail-input"
      value={value}
      disabled={disabled}
      onChange={event => onChange(property, event.target.value)}
    />
  );
}
