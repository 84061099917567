import React from 'react';
import FontAwesome from 'react-fontawesome';
import PostListingContainer from 'app/components/listing/PostListingContainer';
// import PostSimpleListingContainer from 'app/components/listing/PostSimpleListingContainer';
import PostDetailContainer from 'app/components/detail/PostDetailContainer';
import BackupContainer from 'app/components/system/BackupContainer';

const Styles = {
  information: {
    textAlign: 'center',
    marginTop: '30px'
  }
}

export default function App({isLoading, isError}) {
  return (<div>
    {
      isLoading
      ? <div key="test" style={Styles.information}>
        <FontAwesome name='spinner' size='2x' spin />
      </div>
      : isError
        ? <div style={Styles.information}>
          You shall not pass
          <br /><br />
          <FontAwesome name='frown-o' size='3x' />
        </div>
        : <div>
          <PostListingContainer />
          <PostDetailContainer />
          <BackupContainer />
        </div>
    }
  </div>);
}
