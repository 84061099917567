import { call, put, takeLatest, select, fork } from 'redux-saga/effects'
import { mdToHtml } from 'app/utils/markdown';
import * as actions from './actionTypes';

import { updateBlogPost, createBlogPost, fetchBlogPosts } from 'app/api/posts'; // FAKE: api
import { fetchedBlogPosts } from 'app/redux/posts/actions';

function* initialSaga() {
  const posts = yield call(fetchBlogPosts);
  yield put(fetchedBlogPosts(posts));
}

function* fetch(action) {
  try {
    if (action.subtype === actions.SAVE_EDITED_BLOG_POST) {
      const state = yield select();
      const { postId } = state.posts.editing;
      const post = state.posts.entities[postId] || {};
      const content = state.posts.editing.content || post.content || '';
      const editingObject = {
        ...state.posts.editing,
        contentHtml: mdToHtml(content)
      };
      const existing = state.posts.entities[postId] || {};
      const response = postId ? yield call(updateBlogPost, existing, editingObject) : yield call(createBlogPost, editingObject);
      const isSuccess = typeof response !== 'undefined' && response !== null;
      console.log('[Middleware] Blog response: ', response);
      if (isSuccess) {
        const type = postId ? actions.BLOG_POST_UPDATED : actions.BLOG_POST_CREATED;
        yield put({
          type,
          postId: postId || response.postId,
          entity: response
        });
      } else {
        yield put({
          type: actions.FETCH_FAIL,
          postId
        });
      }
    }
  } catch (error) {
    console.log('[Middleware] Fetching error', error);
  }
}

function* fetchLatestSaga() {
  yield takeLatest(actions.FETCH, fetch);
}

export default function* root() {
  yield fork(initialSaga);
  yield fork(fetchLatestSaga);
}