import React from 'react';
import PostContainer from './PostContainer';
import MaterialButton from 'app/components/common/MaterialButton';
import FontAwesome from 'react-fontawesome';
import './PostListing.css';

export default class PostListing extends React.Component {

  shouldComponentUpdate(nextProps) {
    // There should be just few items per page, we should quickly check if is required rendering or not
    return nextProps.posts.some((postId, index) => {
      return postId !== this.props.posts[index];
    });
  }

  render() {
    const { posts, showPrev, showNext, onSlidePrev, onSlideNext, onCreateNew } = this.props;
    return (
     <div className="PostListing-wrap">
        {showPrev && <MaterialButton onClick={onSlidePrev} className="PostListing-move">
          <FontAwesome className="PostListing-moveArrow" name="chevron-left" />
        </MaterialButton>
        }
        {!showPrev && <MaterialButton onClick={onCreateNew} className="PostListing-new">
          <div>
            <FontAwesome name="file" /><br />
            Create New
          </div>
        </MaterialButton>
        }
        {posts.map(postId => <PostContainer key={postId} id={postId} />)}
        {showNext && <MaterialButton onClick={onSlideNext} className="PostListing-move">
          <FontAwesome className="PostListing-moveArrow" name="chevron-right" />
        </MaterialButton>
        }
      </div> 
    );
  }
}
