import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import blogReducers from 'app/redux/posts';
import appReducers from 'app/redux/app';

export function buildStore(middleware) {
  const REDUX_DEV_TOOLS = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : false;
  const composedMiddleware = middleware ? (
    REDUX_DEV_TOOLS ? compose(applyMiddleware(middleware), REDUX_DEV_TOOLS) : compose(applyMiddleware(middleware))
  ) : undefined;

  return createStore(combineReducers({
    app: appReducers,
    posts: blogReducers
  }), composedMiddleware);
}