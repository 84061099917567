import React from 'react';

export default function PostStatesDropdown({ state, onChange, }) {
  return (
    <select value={state}
      onChange={event => onChange(event.target.value)}
      className="PostDetail-dropdown"
      >
      <option value="draft">Draft</option>
      <option value="published">Published</option>
      <option value="archived">Archived</option>
    </select>
  );
}
