// Resize parent application iframe to required height

function onElementHeightChange(elm, callback) {
  let lastHeight = elm.clientHeight, newHeight;
  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight !== newHeight) {
      callback();
    }
    lastHeight = newHeight;
    if (elm.onElementHeightChangeTimer) {
      clearTimeout(elm.onElementHeightChangeTimer);
    }
    elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}

export function bindBodyListener() {
  onElementHeightChange(document.body, function () {
    const body = document.body;
    const element = document.documentElement;
    let height;
    if (typeof document.height !== 'undefined') {
      height = document.height; // For webkit browsers
    } else {
      height = Math.max(body.scrollHeight, body.offsetHeight, element.clientHeight, element.scrollHeight, element.offsetHeight);
    }
    window.parent.postMessage({ height }, '*');
  });
}
