// http://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
export function formatDate(date) {
  if (!date) {
    return '-';
  }
  const dateObj = date ? new Date(date) : null;
  if (!(dateObj instanceof Date)) {
    console.error('Incorrect date');
    return '-';
  }
  return dateObj.toLocaleDateString('SK-sk', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
}
