import * as actions from 'app/redux/actionTypes';

export function entities(state = {}, action) {
  switch (action.type) {
    case actions.FETCHED_BLOG_POSTS: {
      const entities = action.entities || {};
      return entities.posts || {};
    }
    case actions.BLOG_POST_CREATED:
    case actions.BLOG_POST_UPDATED:
      return {
        ...state,
        [action.postId]: {
          ...state[action.postId] || {},
          ...action.entity
        }
      };
    default:
      return state
  }
}

export function listing(state = [], action) {
  switch (action.type) {
    case actions.FETCHED_BLOG_POSTS:
      return action.result || [];
    case actions.BLOG_POST_CREATED:
      return [action.postId, ...state];
    default:
      return state;
  }
}

export function slider(state = { page: 1, pageSize: 5 }, action) {
  switch (action.type) {
    case actions.SLIDE_TO_NEXT_POSTS_PAGE:
      return {
        ...state,
        page: state.page + state.pageSize
      };
    case actions.SLIDE_TO_PREV_POSTS_PAGE: {
      const prevPage = state.page - state.pageSize;
      return {
        ...state,
        page: prevPage < 1 ? 1 : prevPage
      };
    }
    default:
      return state;
  }
}

export function editState(state = { saving: false, saved: false, error: false }, action) {
  switch (action.type) {
    case actions.SELECT_BLOG_POST:
      return {
        saving: false,
        persisted: action.postId !== null,
        error: false
      };
    case actions.CHANGE_BLOG_VALUE:
      return {
        saving: false,
        persisted: false,
        error: false
      };
    case actions.FETCH:
      return {
        saving: true,
        persisted: false,
        error: false
      };
    case actions.BLOG_POST_UPDATED:
    case actions.BLOG_POST_CREATED:
      return {
        saving: false,
        persisted: true,
        error: false
      };
    case actions.FETCH_FAIL:
      return {
        saving: false,
        persisted: false,
        error: true
      };
    default:
      return state
  }
}

export function editing(state = {}, action) {
  switch (action.type) {
    case actions.BLOG_POST_CREATED:
    case actions.SELECT_BLOG_POST:
      return {
        postId: action.postId
      };
    case actions.CHANGE_BLOG_VALUE:
      return {
        ...state,
        [action.property]: action.value
      };
    default:
      return state
  }
}