import * as actions from 'app/redux/actionTypes';

export const editBlogPost = (postId) => ({
  type: actions.SELECT_BLOG_POST,
  postId
});

export const changeBlogValue = (property, value) => ({
  type: actions.CHANGE_BLOG_VALUE,
  property,
  value
});

export const saveEditedBlogPost = () => ({
  type: actions.FETCH,
  subtype: actions.SAVE_EDITED_BLOG_POST,
});

export const slideToPrevPage = () => ({
  type: actions.SLIDE_TO_PREV_POSTS_PAGE
});

export const slideToNextPage = () => ({
  type: actions.SLIDE_TO_NEXT_POSTS_PAGE
});

export const fetchedBlogPosts = (normalized) => ({
  type: actions.FETCHED_BLOG_POSTS,
  ...normalized
});