import { connect } from 'react-redux';
import { editBlogPost } from 'app/redux/posts/actions';
import Post from './Post';

const mapStateToProps = (state, ownProps) => {
  const post = state.posts.entities[ownProps.id] || {};
  const title = post.title || '{Unknown}';
  const { url, postState, created, updated } = post;
  return {
    id: ownProps.id,
    isSelected: state.posts.editing.postId === ownProps.id,
    isPublished: postState === 'published',
    created,
    updated,
    title,
    url
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onPostSelect() {
    dispatch(editBlogPost(ownProps.id));
  }
});

const PostContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Post);

export default PostContainer;