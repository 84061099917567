import * as firebase from 'firebase';

let _database = {};

function login() {
  return firebase
    .auth()
    .signInWithPopup(new firebase.auth.GoogleAuthProvider())
    .then(result => {
      // result contains {result.user, credential.accessToken} - User and Google Access Token.
      console.log('[api] Database connection established');
      _database = firebase.database();
      console.log(_database);
    })
    .catch(error => {
      // error contains {code, message, email, credentials}
      console.log('[api] Cannot auth in firebase', error);
    });
}

// https://firebase.google.com/docs/auth/web/start
export function initializeApi() {
  // TODO: hide to env?
  firebase.initializeApp({
    apiKey: 'AIzaSyB9zwpSB7jyYiKe5w6pK0Aw-81_YAFTL8Y',
    authDomain: 'nexitblog.firebaseapp.com',
    databaseURL: 'https://nexitblog.firebaseio.com',
    projectId: 'nexitblog',
    storageBucket: 'nexitblog.appspot.com',
    messagingSenderId: '124413761437'
  });
  return new Promise(function(resolve, reject) {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log(`[Firebase] User ${user.uid} signed in`);
        _database = firebase.database();
        return resolve();
      }
      console.log('[Firebase] User is NOT logged');
      login()
        .then(() => resolve())
        .catch(() => reject());
    });
  });
}

/**
 * Expose database instance for API
 */
export const database = () => _database;
