import marked from 'marked';
import domPurify from 'dompurify';

const renderer = new marked.Renderer();

renderer.heading = (text, level) => `<h${level}>${text}</h${level}>`;

marked.setOptions({
  renderer,
  gfm: true,
  tables: true,
  breaks: false,
  pedantic: false,
  // TODO: marked(): sanitize and sanitizer parameters are deprecated since version 0.7.0, should not be used and will be removed in the future. Read more here: https://marked.js.org/#/USING_ADVANCED.md#options
  // sanitize: true,
  smartLists: true,
  smartypants: false
});

export function mdToHtml(text) {
  console.log('[markdown] Converting text to Markdown...');

  return domPurify.sanitize(marked(text));
}
