import { connect } from 'react-redux';
import { changeBlogValue, saveEditedBlogPost } from 'app/redux/posts/actions';
import { checkUniqueUrl } from 'app/redux/posts';
import PostDetail from './PostDetail';

const mapStateToProps = (state, ownProps) => {
  const editPost = state.posts.editing;
  const id = editPost.postId;
  const post = state.posts.entities[id] || {};
  const url = editPost.url || post.url || '';
  const isUniqueUrl = checkUniqueUrl(url, state.posts.entities, id);
  return {
    id,
    isPersisted: state.posts.editState.persisted,
    isSaving: state.posts.editState.saving,
    isError: state.posts.editState.error,
    created: post.created ? new Date(post.created) : null,
    updated: post.updated ? new Date(post.updated) : null,
    // Editable
    postState: editPost.postState || post.postState,
    title: editPost.title || post.title || 'New post title...',
    description: editPost.description || post.description || '',
    image: editPost.image || post.image || '',
    url,
    isUniqueUrl,
    content: editPost.content || post.content || ''
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onValueChange(property, value) {
    dispatch(changeBlogValue(property, value));
  },
  onSave() {
    dispatch(saveEditedBlogPost());
  }
});

const PostDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostDetail);

export default PostDetailContainer;