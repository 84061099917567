import { connect } from 'react-redux';
import App from './App';

const mapStateToProps = (state, ownProps) => {
  const appState = state.app.state;
  const isLoading = appState.loading;
  const isError = appState.error;
  return {
    isLoading,
    isError
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  // empty
});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;